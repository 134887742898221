import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  @font-face {
    font-style: normal;
  }
  @font-face {
    font-style: normal;
  }
  @font-face {
    font-style: normal;
  }

`;

export default Typography;
